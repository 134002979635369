.reportClass {
    flex-grow: 1;
}

.powerBiContainerClass {
    width: 100%;
    margin-left: -24px;
    margin-top: -16px;
    margin-right: -24px;
    display: flex;
    flex-direction: column;
}
